import i18n from "i18next";

export const currentLang = () => {
  return i18n.language;
};

export const SidePosition = (num: string = "0") => {
  return currentLang() === "ar" ? { right: `${num}` } : { left: `${num}` };
};

export const SideMargin = (num: string = "0") => {
  return currentLang() === "ar" ? { marginRight: `${num}` } : { marginLeft: `${num}` };
};

export const SidePadding = (num: string = "0") => {
  return currentLang() === "ar" ? { paddingRight: `${num}` } : { paddingLeft: `${num}` };
};

export const SideText = () => {
  return currentLang() === "ar"
    ? ({ textAlign: "right" } as React.CSSProperties)
    : ({ textAlign: "left" } as React.CSSProperties);
};

export const RotateImage = () => {
  return currentLang() === "ar" ? { transform: "scaleX(-1)" } : {};
};

export const OppositeSidePosition = (num: string = "0") => {
  return currentLang() === "ar" ? { left: `${num}` } : { right: `${num}` };
};

export const OppositeSideMargin = (num: string = "0") => {
  return currentLang() === "ar" ? { marginLeft: `${num}` } : { marginRight: `${num}` };
};

export const OppositeSidePadding = (num: string = "0") => {
  return currentLang() === "ar" ? { paddingLeft: `${num}` } : { paddingRight: `${num}` };
};

export const OppositeSideText = () => {
  return currentLang() === "ar"
    ? ({ textAlign: "left" } as React.CSSProperties)
    : ({ textAlign: "right" } as React.CSSProperties);
};

export const OppositeRotateImage = () => {
  return currentLang() === "en" ? { transform: "scaleX(-1)" } : {};
};

export const UpsideRotateImage = () => {
  return currentLang() === "en" ? { transform: "rotate(180deg)" } : {};
};
