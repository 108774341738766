/**
 * Class storage
 * @example Storage.set('KEY', value :any, ?Date(...))
 * @example Storage.get('KEY')
 * @example Storage.remove('KEY')
 */

const Storage = {
  set(key: string, value: string | any): void {
    if (!key) {
      return;
    }

    localStorage.setItem(key, JSON.stringify(value));
  },

  get(key: string): any {
    let value = null;
    if (!!localStorage[key]) value = JSON.parse(localStorage[key]);
    return value;
  },

  check(key: string): boolean {
    return !!localStorage[key] || false;
  },

  remove(key: string): void {
    localStorage.removeItem(key);
  },

  removeAll(): void {
    const list = Object.keys(localStorage);
    list.forEach((key) => {
      localStorage.removeItem(key);
    });
  },
};

export default Storage;
