import { AbilityBuilder, Ability } from "@casl/ability";
import { STORAGE_CONSTRAINT } from "../enums/storage";
import Storage from "./storage";
import { defineAbility } from "@casl/ability";

const { build } = new AbilityBuilder(Ability);

export default defineAbility((can, cannot) => {
  const permissions: any = Storage.get(STORAGE_CONSTRAINT.USER_PERMISSIONS);
  if (!permissions) return;
  const user: any = Storage.get(STORAGE_CONSTRAINT.USER_PROFILE);
  const keys: Array<string> = Object.keys(permissions);

  for (let i = 0; i < keys.length; i++) {
    const action = permissions[keys[i]];
    const model = keys[i];

    can(action, model, [model], user);
  }
  return build();
});
