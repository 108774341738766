import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./i18n.js";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "../src/assets/styles/bootstrap.scss";
import _ from "lodash";
import moment from "moment";
import { AbilityContext } from "./components/can";
import ability from "./core/helpers/ability";

// import lodash globally to decrease the import consumption
window["_"] = _;
window["moment"] = moment;

ReactDOM.render(
  <React.StrictMode>
    <AbilityContext.Provider value={ability}>
      <App />
    </AbilityContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
