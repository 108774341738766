import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router } from "react-router-dom";
import { currentLang } from "./core/helpers/style.helper";
import AppRouting from "./app-routing";
import "./App.scss";
import Storage from "./core/helpers/storage";
import { STORAGE_CONSTRAINT } from "./core/enums/storage";
import ReactTooltip from "react-tooltip";
import React from "react";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import { parse, stringify } from "query-string";
function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
    if (Storage.get(STORAGE_CONSTRAINT.APP_TYPE) !== STORAGE_CONSTRAINT.APP_VERSION) {
      Storage.removeAll();
    }
  }, []);
  useEffect(() => {
    i18n.changeLanguage("en");
  }, [i18n]);

  return (
    <div dir={currentLang() === "ar" ? "rtl" : "ltr"}>
      <Router>
        <QueryParamProvider
          adapter={ReactRouter5Adapter}
          options={{
            searchStringToObject: parse,
            objectToSearchString: stringify,
          }}
        >
          <ReactTooltip id="ReactTooltip" effect="solid" />
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={currentLang() === "ar"}
            pauseOnFocusLoss
            pauseOnHover
            theme="colored"
          />
          <AppRouting />
        </QueryParamProvider>
      </Router>
    </div>
  );
}

export default App;
