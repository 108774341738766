import axiosObject from "../configs/axios.config";
import { HttpMethods } from "../enums/http-methods.enum";

export const baseURL = process.env.REACT_APP_BACKEND_CORE_URL;

export type MakeRequest = {
  url: string;
  method: HttpMethods;
  data?: Object | undefined;
  params?: Object | undefined;
  headers?: Object | undefined;
};

export const makeRequest: any = async (req: MakeRequest) => {
  const { url, method, data, params, headers } = req;
  return axiosObject({
    url,
    method,
    data,
    params,
    headers,
  }).then((res) => {
    return res.data;
  });
};
